import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import withPercentageIncomeBadge from '@shared/ui/badges/withPercentageIncomeBadge';
import { ContainerRow } from '@src/components/styled';
import { Title } from '@src/components/styled/Typography';
import MonthlyReturnPositions from '../MonthlyReturnPositions';
const IncomeBadge = withPercentageIncomeBadge(Title);
const Container = styled(ContainerRow)(() => ({
    width: 'auto',
    alignItems: 'center',
    justifyContent: 'right',
    gap: 10,
}));
const PortfolioMonthlyReturn = ({ portfolio, userSubscribed }) => {
    const monthlyReturnDifference = () => {
        var _a;
        return (userSubscribed ?
            (_a = portfolio.user_subscription_summary) === null || _a === void 0 ? void 0 : _a.past_month_difference :
            portfolio.followers.average_followers_monthly_return);
    };
    const firstPosition = {
        position: 1,
        returnValue: portfolio.followers.average_followers_monthly_return_first,
    };
    const lastPosition = portfolio.followers.count > 1 ? {
        position: portfolio.followers.count,
        returnValue: portfolio.followers.average_followers_monthly_return_last,
    } : undefined;
    return (_jsxs(Container, { children: [_jsx(IncomeBadge, { badgeVariant: 'plain', percentageIncome: monthlyReturnDifference() || 0, incomeSymbol: 'approximation' }), _jsx(MonthlyReturnPositions, { first: firstPosition, last: lastPosition })] }));
};
export default PortfolioMonthlyReturn;
